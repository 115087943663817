@font-face {
  font-family: Stanford;
  src: url('./fonts/stanford.ttf');
}
@font-face {
  font-family: "Charles-Sebastian";
  src: url('./fonts/charles-sebastian.otf');
}
@font-face {
  font-family: Montserrat;
  src: url('./fonts/montserrat-regular.ttf');
}
@font-face {
  font-family: "A-Little-Sunshine";
  src: url('./fonts/a-little-sunshine.ttf');
}
@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica.ttf");
}
@font-face {
  font-family: "Calibri";
  src: url("./fonts/Calibri.ttf");
}
@font-face {
  font-family: "Calibri-Bold";
  src: url("./fonts/Calibri-Bold.ttf");
}
@font-face {
  font-family: "Gilroy-Black";
  src: url("./fonts/Gilroy-Black.ttf");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("./fonts/Gilroy-Medium.ttf");
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: "Greycliff-CF-Bold";
  src: url("./fonts/Greycliff-CF-Bold.otf");
}
@font-face {
  font-family: "Muli-Extra-Light";
  src: url("./fonts/Muli-Extra-Light.ttf");
}
@font-face {
  font-family: "Simplicity";
  src: url("./fonts/Simplicity.ttf");
}
@font-face {
  font-family: "Satoshi-Black";
  src: url("./fonts/Satoshi-Black.otf");
}
@font-face {
  font-family: "Satoshi-Regular";
  src: url("./fonts/Satoshi-Regular.otf");
}
@font-face {
  font-family: "The-Sans-LBS";
  src: url("./fonts/The-Sans-LBS.otf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.text-bg-confirmed {
  background-color: #0d6efd
}
.form-select-entries {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.skeleton {
  background: #ddd;
  margin: 8px 0;
  border-radius: 0.5em;
}
.skeleton-wrapper {
  overflow: hidden;
}
.skeleton.text {
  overflow: hidden;
  height: 12px;
  width: 100%;
}
.skeleton.text-small {
  overflow: hidden;
  height: 8px;
  width: 100%;
}
.skeleton.title {
  height: 20px;
  width: 100%;
  margin-bottom: 16px;
}
.skeleton.avatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.skeleton.thumbnail {
  height: 100px;
  width: 100px;
}
.shimmer-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 1.0s linear infinite;
}
.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  transform: skewX(-20deg);
  box-shadow: 0 0 28px 28px rgba(255, 255, 255, 0.05);
}
@keyframes loading {
  0% { transform: translateX(-150%) }
  50% { transform: translateX(-60%) }
  100% { transform: translateX(150%) }
}
@keyframes leftToRight {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(100%);
  }
}
/* Sets offset for hash link scroll position  */
/* :target {
  scroll-margin-top: 112px;
} */
.horizontal-steps:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: #eef2f7;
}
.horizontal-steps .process-line {
  display: block;
  position: absolute;
  width: 50%;
  height: 0.2em;
  background-color: var(--ed-primary-color, #ff5129);
}
.horizontal-steps, .horizontal-steps .horizontal-steps-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  height: 8px;
  width: 8px;
  margin: 0 2em;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: var(--ed-primary-color, #ff5129);
  background-color: currentColor;
  border: 0.25em solid #fafbfe;
  border-radius: 50%;
  z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
  margin-right: 0;
  color: #10c469;
}
.horizontal-steps .horizontal-steps-content .step-item span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  color: #4d4e51;
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  display: block;
  position: absolute;
  top: 47.5%;
  left: 51%;
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  -webkit-animation-name: animation-steps-current;
  animation-name: animation-steps-current;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
  font-weight: bold;
}
@-webkit-keyframes animation-steps-current {
  from {
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
  }
  to {
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
  }
}
@keyframes animation-steps-current {
  from {
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
  }
  to {
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
      white-space: inherit;
  }
}
body[data-layout-color="dark"] .horizontal-steps:before {
  background-color: #37404a;
}
body[data-layout-color="dark"] .horizontal-steps .horizontal-steps-content .step-item {
  border: 0.25em solid var(--ct-body-bg);
}
body[data-layout-color="dark"] .horizontal-steps .horizontal-steps-content .step-item span {
  color: #aab8c5;
}
.collapsing {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  transition: none !important;
}
.avatar {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
.avatar-letters {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.progress-bar-custom {
  height: 2px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  margin: 1rem 0;
}
.progress-bar-custom-value {
  width: 100%;
  height: 100%;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}
.product-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  max-width: 50px;
  max-height: 50px;
  height: auto;
}
.bundle-card.selected, .product-child-card.selected {
  border: 1px solid var(--ed-primary-color, '#ff5129');
  box-shadow: 0 0 0 1px var(--ed-primary-color, '#ff5129');
  border-radius: 0.5rem;
}
.template-card.selected, .product-card.selected {
  border: 1px solid var(--ed-primary-color, '#ff5129');
  box-shadow: 0 0 0 1px var(--ed-primary-color, '#ff5129');
  overflow: hidden;
}
.product-card:hover, .bundle-card:hover, .template-card:hover {
  box-shadow: 0 0 0 0.25rem var(--ed-primary-reduce-opacity, rgba(230, 42, 0, 0.5));
  border-radius: 0.5rem;
}
.bundle-card .img-fluid, .product-card .img-fluid, .template-card .img-fluid, .template-card .card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.post-thumb
{
  background-color: #f2f2f2;
  border: 1px solid #cfd9db;
  border-radius: .25rem .25rem 0 0;
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 60% 0 0 0;
  overflow: hidden;
}
img.img-poster
{
  position: absolute;
  display: block;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.shop-thumb{
  background-color: #f2f2f2;
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 8% 0 0 0;
  overflow: hidden;
}
img.img-shop{
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
  position: static;
}
.capitalize-first-letter:first-letter{
  text-transform: capitalize
}
.cursor-pointer {cursor: pointer;}
.cursor-crosshair {cursor: crosshair;}
.cursor-eresize {cursor: e-resize;}
.cursor-move {cursor: move;}
.ql-picker.ql-placeholderDropdown .ql-picker-label {
  padding-right: 20px;
}
.ql-snow .ql-picker.ql-font {
  width: 144px !important; 
}
/* static label */
.ql-picker.ql-placeholderDropdown .ql-picker-label:before {
  content: "Placeholder";     /* never changes its content as this menu's label changes */
}
.ql-placeholderDropdown .ql-picker-item:before {
  content: attr(data-value);
}
.ql-font-stanford {
  font-family: "Stanford";
}
.ql-font-montserrat {
  font-family: "Montserrat";
}
.ql-font-charles-sebastian {
  font-family: "Charles-Sebastian";
}
.ql-font-a-little-sunshine {
  font-family: "A-Little-Sunshine";
}
.ql-font-helvetica {
  font-family: "Helvetica";
}
.ql-font-calibri {
  font-family: "Calibri";
}
.ql-font-calibri-bold {
  font-family: "Calibri-Bold";
}
.ql-font-gilroy-black {
  font-family: "Gilroy-Black";
}
.ql-font-gilroy-medium {
  font-family: "Gilroy-Medium";
}
.ql-font-gilroy-regular {
  font-family: "Gilroy-Regular";
}
.ql-font-greycliff-cf-bold {
  font-family: "Greycliff-CF-Bold";
}
.ql-font-muli-extra-light {
  font-family: "Muli-Extra-Light";
}
.ql-font-simplicity {
  font-family: "Simplicity";
}
.ql-font-satoshi-black {
  font-family: "Satoshi-Black";
}
.ql-font-satoshi-regular {
  font-family: "Satoshi-Regular";
}
.ql-font-the-sans-lbs {
  font-family: "The-Sans-LBS";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=stanford]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=stanford]::before {
  content: attr(data-value);
  font-family: "Stanford";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=montserrat]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=montserrat]::before {
  content: attr(data-value);
  font-family: "Montserrat";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="charles-sebastian"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="charles-sebastian"]::before {
  content: "Charles Sebastian";
  font-family: "Charles-Sebastian";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="a-little-sunshine"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="a-little-sunshine"]::before {
  content: "A Little Sunshine";
  font-family: "A-Little-Sunshine";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=helvetica]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=helvetica]::before {
  content: attr(data-value);
  font-family: "Helvetica";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=calibri]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=calibri]::before {
  content: attr(data-value);
  font-family: "Calibri";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="calibri-bold"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="calibri-bold"]::before {
  content: "Calibri Bold";
  font-family: "Calibri-Bold";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="gilroy-black"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="gilroy-black"]::before {
  content: "Gilroy Black";
  font-family: "Gilroy-Black";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="gilroy-medium"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="gilroy-medium"]::before {
  content: "Gilroy Medium";
  font-family: "Gilroy-Medium";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="gilroy-regular"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="gilroy-regular"]::before {
  content: "Gilroy Regular";
  font-family: "Gilroy-Regular";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="greycliff-cf-bold"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="greycliff-cf-bold"]::before {
  content: "Greycliff CF Bold";
  font-family: "Greycliff-CF-Bold";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="muli-extra-light"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="muli-extra-light"]::before {
  content: "Muli Extra Light";
  font-family: "Muli-Extra-Light";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=simplicity]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=simplicity]::before {
  content: attr(data-value);
  font-family: "Simplicity";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="satoshi-black"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="satoshi-black"]::before {
  content: "Satoshi Black";
  font-family: "Satoshi-Black";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="satoshi-regular"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="satoshi-regular"]::before {
  content: "Satoshi Regular";
  font-family: "Satoshi-Regular";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="the-sans-lbs"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="the-sans-lbs"]::before {
  content: "The Sans LBS";
  font-family: "The-Sans-LBS";
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before {
  font-weight: bold !important;
  font-family: "Helvetica" !important;
  text-wrap: nowrap;
  text-transform: capitalize;
}
.ql-snow .ql-picker.ql-font .ql-picker-item::before{
  text-wrap: nowrap;
  text-transform: capitalize;
}
.ql-toolbar {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.ql-container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.greeting-card-editor .ql-snow{
  .ql-picker{
    &.ql-size{
      .ql-picker-label,
      .ql-picker-item{
        &::before{
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.form-select-custom {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.zero-btn-disabled {
border: 0px solid #fff !important;
}
.btn-rounded {
  border-radius: 50%;
}
.mh-280 {
  min-height: 280px;
}
@keyframes pulse {
  0% { -webkit-transform: scale(0.1, 0.1); opacity: 0.0; }
  50% { opacity: 1.0; }
  100% { -webkit-transform: scale(1.2, 1.2); opacity: 0.0; }
}
.badge-animation {
  padding: 0.7rem !important;
  animation: pulse 1s infinite;
}
.swatch {
  padding: 2px;
  background: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: 'inline-block';
  height:24px;
  width: 24px;
  cursor: 'pointer';
}
.colorPicker{
  width: 20px;
  height:20px;
  border-radius: 0.2rem;
}
.popover-custom {
  position: absolute;
  z-index: 2;
}
/**[Start theme]  navigation bar*/
.sb-sidenav-dark {
  background-color: var(--ed-background-color,#212529) !important;
  color: rgba(255, 255, 255, 0.5);
}
.sb-topnav {
  padding-left: 0;
  height: 56px;
  z-index: 1039;
  background-color: var(--ed-background-color, #212529 )!important;
  border-bottom: 0.5px solid  rgba(0, 0, 0, 0.125);
}
.sb-sidenav {
  border-right: 0.5px solid rgba(0, 0, 0, 0.125);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link {
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
  color: var(--ed-foreground-color) !important;
  border-radius: 1.0em;
  margin-right: 0.75em;
  margin-left: 0.75em;
}
.sb-topnav.navbar-dark #sidebarToggle {
  color: var(--ed-foreground-color,rgba(255, 255, 255, 0.5)) !important;
}
.sb-topnav.navbar-dark #sidebarToggle:focus {
  color: var(--ed-foreground-color, rgba(255, 255, 255, 0.5)) !important;
  box-shadow: 0 0 0 0.25rem var(--ed-foreground-reduce-opacity,rgba(255, 255, 255, 0.2))!important;
}
.sb-topnav.navbar-dark #sidebarToggle:hover {
  color: var(--ed-foreground-color,rgba(255, 255, 255, 0.5)) !important;
}
.sb-sidenav-footer{
  background-color:var(--ed-background-color) !important;
}
.sb-sidenav-footer .btn-dark {
  color: #fff;
  background-color: #000;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--ed-accent-color, #fff);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ed-primary-color, #ff5129);
  transition: width 0.6s ease;
}
.list-group-item.active {
  z-index: 2;
  color: var(--ed-accent-color, #fff);
  background-color: var(--ed-primary-color, #ff5129) !important;
  border-color: var(--ed-primary-color, #ff5129) !important
}
td > a {
  color:var(--ed-primary-color, #ff5129) !important;
}
.img-navbar-logo{
  max-height: 50px !important;
  max-width: 8rem !important;
  overflow: hidden !important;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
  background-color: var(--ed-foreground-color, #212529);
  color: var(--ed-background-color, #d3d4d4) !important;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
  background-color: var(--ed-foreground-color, #212529);
  color: var(--ed-background-color, #d3d4d4) !important;
  filter: brightness(85%);
}
.avatar {
  background-color: var(--ed-foreground-color, #212529);
  color: var(--ed-background-color, #d3d4d4) !important;
}

.rounded-bottom-5 {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.rounded-top-5 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.bundle-card .accordion-button::before, .order-cart .accordion-button::before {
  flex-shrink: 0;
  color: var(--ed-primary-color, '#ff5129');
  width: 1.25rem;
  height: 1.25rem;
  margin-right: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.order-cart .accordion-button::before {
  margin-right: 1rem;
}
.bundle-card .accordion-button.show::before, .order-cart .accordion-button:not(.collapsed)::before {
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform-origin: center;
  transform: rotate(180deg);
}
.accordion-button .accordion-button-text::before, .shipping-info-details .accordion-button-text::before {
  content: "View";
}
.accordion-button.show .accordion-button-text::before, .shipping-info-details.show .accordion-button-text::before {
  content: "Hide";
}
.square-container
{
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 100% 0 0 0;
  overflow: hidden;
  border-radius: 0.5rem;
}
img.square-image
{
  position: absolute;
  display: block;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0.5rem;
}
.product-description img {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 300px;
  display: block;
}
@keyframes borderAnimation {
  0% {
    border-color: #D3D4D4;
  }
  50% {
    border-color: #A3A4A4;
  }
  100% {
    border-color: #D3D4D4;
  }
}
